var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('navigation.levelsScore'))+" ")]},proxy:true},(_vm.hasAccess('levels_score', 'create'))?{key:"button",fn:function(){return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.createUpdateModal",modifiers:{"createUpdateModal":true}}],staticClass:"ml-auto",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}:null],null,true)}),_c('div',{staticClass:"card"},[_c('b-table',{staticClass:"b-table--less-padding",attrs:{"striped":"","borderless":"","outlined":"","responsive":true,"fields":_vm.tableHeader,"items":_vm.levelsScore},scopedSlots:_vm._u([{key:"cell(index)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"cell(level)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.level.name[_vm.localLang])+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('table-action-btns',{attrs:{"item":item,"index":index,"delete-handler":_vm.deleteLevelScore,"delete-access":{ section: 'levels_score', permission: 'delete' },"update-access":{ section: 'levels_score', permission: 'update' }},on:{"openModal":_vm.openModalToUpdate}})]}}])})],1),_c('b-modal',{attrs:{"id":"createUpdateModal","cancel-variant":"danger","centered":"","hide-footer":false,"no-close-on-backdrop":false,"size":"sm","title":_vm.$t('add.score')},on:{"show":_vm.onOpenModal,"hidden":_vm.onCloseModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.modalSubmit}},[_vm._v(" "+_vm._s(_vm.modalSubmitBtnText)+" ")])]},proxy:true}]),model:{value:(_vm.isOpenModal),callback:function ($$v) {_vm.isOpenModal=$$v},expression:"isOpenModal"}},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.score'))+" ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.score'),"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"size":"lg","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('enter.score')},model:{value:(_vm.dataForModal.score),callback:function ($$v) {_vm.$set(_vm.dataForModal, "score", $$v)},expression:"dataForModal.score"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.step'))+" ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.step'),"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"size":"lg","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('enter.step')},model:{value:(_vm.dataForModal.step),callback:function ($$v) {_vm.$set(_vm.dataForModal, "step", $$v)},expression:"dataForModal.step"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('navigation.levels'))+" ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('navigation.levels'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('v-select',{class:[
                                    'select-size-lg',
                                    {
                                        'v-select--is-invalid': errors.length > 0
                                    }
                                ],attrs:{"label":"name","placeholder":_vm.$t('choose.level'),"options":_vm.levels,"reduce":function (level) { return level.id; },"searchable":false},model:{value:(_vm.dataForModal.level_id),callback:function ($$v) {_vm.$set(_vm.dataForModal, "level_id", $$v)},expression:"dataForModal.level_id"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }