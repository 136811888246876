<script>
import api from '@/services/api';
import { BButton, BCol, BFormGroup, BFormInput, BRow, BTable } from 'bootstrap-vue';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import vSelect from 'vue-select';
import { updateObjectToNew } from '@/util/helper';
import TableActionBtns from '@/components/TableActionBtns';
import PageTitle from '@/components/PageTitle';

export default {
    name: 'LevelsScore',
    components: {
        PageTitle,
        TableActionBtns,
        BButton,
        BTable,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        vSelect
    },
    mixins: [CRUDPageMixin],
    data: () => ({
        levels: [],
        levelsScore: [],
        dataForModal: {
            score: null,
            step: null,
            level_id: null
        }
    }),
    computed: {
        tableHeader() {
            return [
                {
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'level',
                    label: this.$t('titles.level')
                },
                {
                    key: 'score',
                    label: this.$t('titles.score')
                },
                {
                    key: 'step',
                    label: this.$t('titles.step')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        }
    },
    methods: {
        async getLevelsScore() {
            const { data } = await api.levels.getLevelScores();
            this.levelsScore = data;
            this.sortByLevel();
        },

        async createLevelScore() {
            const { data } = await api.levels.createLevelScore(
                this.dataForModal.level_id,
                this.dataForModal
            );
            this.levelsScore.push(data);
            this.$toast.success(this.$t('success.added'));
        },

        async updateLevelScore() {
            const { data } = await api.levels.updateLevelScore(
                this.updatingItem.level.id,
                this.updatingItem.id,
                this.dataForModal
            );
            this.levelsScore.forEach((score) => {
                if (score.id === this.updatingItem.id) {
                    updateObjectToNew(score, data);
                }
            });
            this.$toast.success(this.$t('success.updated.title'));
        },

        async deleteLevelScore(scoreID, index) {
            const levelID = this.levelsScore.find((item) => {
                if (item.id === scoreID) return item;
            }).level.id;
            await api.levels.deleteLevelScore(levelID, scoreID);
            this.levelsScore.splice(index, 1);
        },

        async getLevels() {
            const { data } = await api.levels.get();
            this.levels = data.map((level) => {
                level.name = level.name[this.localLang];
                return level;
            });
        },

        onOpenModal() {
            if (!this.levels.length) {
                this.getLevels();
            }
        },

        modalSubmit() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    try {
                        if (!this.updatingItem) {
                            await this.createLevelScore();
                        } else {
                            await this.updateLevelScore();
                        }
                        this.sortByLevel();
                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        sortByLevel() {
            this.levelsScore = this.levelsScore.sort((a, b) => {
                if (a.level.id === b.level.id) {
                    return a.score < b.score ? -1 : a.score > b.score ? 1 : 0;
                } else {
                    return a.level.id < b.level.id ? -1 : 1;
                }
            });
        }
    },
    mounted() {
        this.getLevelsScore();
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title>
                {{ $t('navigation.levelsScore') }}
            </template>
            <template #button v-if="hasAccess('levels_score', 'create')">
                <b-button v-b-modal.createUpdateModal variant="success" class="ml-auto">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <div class="card">
            <b-table
                class="b-table--less-padding"
                striped
                borderless
                outlined
                :responsive="true"
                :fields="tableHeader"
                :items="levelsScore"
            >
                <template #cell(index)="{ index }">
                    {{ index + 1 }}
                </template>
                <template #cell(level)="{ item }">
                    {{ item.level.name[localLang] }}
                </template>
                <template #cell(actions)="{ item, index }">
                    <table-action-btns
                        :item="item"
                        :index="index"
                        :delete-handler="deleteLevelScore"
                        :delete-access="{ section: 'levels_score', permission: 'delete' }"
                        :update-access="{ section: 'levels_score', permission: 'update' }"
                        @openModal="openModalToUpdate"
                    />
                </template>
            </b-table>
        </div>

        <b-modal
            v-model="isOpenModal"
            id="createUpdateModal"
            cancel-variant="danger"
            @show="onOpenModal"
            @hidden="onCloseModal"
            centered
            :hide-footer="false"
            :no-close-on-backdrop="false"
            size="sm"
            :title="$t('add.score')"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    SCORE    -->
                    <b-col cols="12">
                        <label>
                            {{ $t('titles.score') }}
                        </label>
                        <ValidationProvider
                            :name="$t('titles.score')"
                            rules="required|numeric"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.score"
                                    size="lg"
                                    :state="errors.length > 0 ? false : null"
                                    :placeholder="$t('enter.score')"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    STEP    -->
                    <b-col cols="12">
                        <label>
                            {{ $t('titles.step') }}
                        </label>
                        <ValidationProvider
                            :name="$t('titles.step')"
                            rules="required|numeric"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.step"
                                    size="lg"
                                    :state="errors.length > 0 ? false : null"
                                    :placeholder="$t('enter.step')"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    LEVELS    -->
                    <b-col cols="12">
                        <label>
                            {{ $t('navigation.levels') }}
                        </label>
                        <ValidationProvider
                            :name="$t('navigation.levels')"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <v-select
                                    v-model="dataForModal.level_id"
                                    label="name"
                                    :placeholder="$t('choose.level')"
                                    :options="levels"
                                    :class="[
                                        'select-size-lg',
                                        {
                                            'v-select--is-invalid': errors.length > 0
                                        }
                                    ]"
                                    :reduce="(level) => level.id"
                                    :searchable="false"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </ValidationObserver>
            <template #modal-footer>
                <b-button @click="modalSubmit" variant="success">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
